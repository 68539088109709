// @mui
import { alpha } from '@mui/material/styles';
//
import palette from './palette';
// ----------------------------------------------------------------------
var themeColor = palette('light');
var LIGHT_MODE = themeColor.grey[500];
var DARK_MODE = themeColor.common.black;
function createShadow(color) {
    var transparent = alpha(color, 0.16);
    return {
        z1: "0 1px 2px 0 ".concat(transparent),
        z4: "0 4px 8px 0 ".concat(transparent),
        z8: "0 8px 16px 0 ".concat(transparent),
        z12: "0 12px 24px -4px ".concat(transparent),
        z16: "0 16px 32px -4px ".concat(transparent),
        z20: "0 20px 40px -4px ".concat(transparent),
        z24: "0 24px 48px 0 ".concat(transparent),
        //
        primary: "0 8px 16px 0 ".concat(alpha(themeColor.primary.main, 0.24)),
        info: "0 8px 16px 0 ".concat(alpha(themeColor.info.main, 0.24)),
        secondary: "0 8px 16px 0 ".concat(alpha(themeColor.secondary.main, 0.24)),
        success: "0 8px 16px 0 ".concat(alpha(themeColor.success.main, 0.24)),
        warning: "0 8px 16px 0 ".concat(alpha(themeColor.warning.main, 0.24)),
        error: "0 8px 16px 0 ".concat(alpha(themeColor.error.main, 0.24)),
        //
        card: "0 0 2px 0 ".concat(alpha(color, 0.2), ", 0 12px 24px -4px ").concat(alpha(color, 0.12)),
        dialog: "-40px 40px 80px -8px ".concat(alpha(color, 0.24)),
        dropdown: "0 0 2px 0 ".concat(alpha(color, 0.24), ", -20px 20px 40px -4px ").concat(alpha(color, 0.24)),
    };
}
export default function customShadows(themeMode) {
    return themeMode === 'light' ? createShadow(LIGHT_MODE) : createShadow(DARK_MODE);
}
